import {ADD_FREE_PRODUCTS_PUBLIC_DATA_KEYS} from '@wix/wixstores-client-storefront-sdk/dist/src/services/AddToCartService/constants';

export const translationPath = (baseUrl, locale) => `${baseUrl}assets/locales/addToCart/addToCart_${locale}.json`;

export const TRACK_EVENT_COLLECTION = 'All Products';
export const ADD_TO_CART_FEDOPS_APP_NAME = 'wixstores-add-to-cart';
export const ADD_TO_CART_FEDOPS_INTERACTION = 'add-to-cart';
export const ADD_TO_CART_TRACK_EVENT = 'AddToCart';

export const PUBLIC_DATA_KEYS = {
  ...ADD_FREE_PRODUCTS_PUBLIC_DATA_KEYS,
  BUTTON_TEXT: 'ADD_TO_CART_BUTTON_TEXT',
  PRODUCT_ID: 'ADD_TO_CART_PRODUCT_ID',
};

export const enum graphQlOperations {
  GetProductById = 'getProductById',
  GetProducts = 'getProducts',
}

export const SPECS = {
  EDITOR_OOI: 'specs.stores.EditorOOI',
  AddToCartBtnLoaderState: 'specs.stores.AddToCartBtnLoaderState',
};

export enum AddToCartActionStatus {
  IDLE = 0,
  SUCCESSFUL = 1,
  FAILED = 2,
  IN_PROGRESS = 3,
}

export const originPage = 'add_to_cart_button';

export enum Experiments {}
